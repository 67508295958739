import { atom } from "jotai";

import { GUEST_FREIGHT_VIEWER_KEY } from "@sellernote/shared/src/constants/forwarding/schedule";
import { UserPort } from "@sellernote/shared/src/types/common/common";
import { getAppTodayMidnight } from "@sellernote/shared/src/utils/common/date";

import { atomWithReset, atomWithStorage } from "jotai/utils";

type GuestFreightViewerData = {
  /** 한국의 오늘 날짜 */
  appTodayMidnight: Date;
  /** 비회원이 오늘 스케줄을 조회한 횟수 */
  todayGuestViewCount: number;
  /** 조회한 스케줄 아이디 리스트(전체) */
  allTimeViewedScheduleIdList: number[];
  /** 오늘 제한 횟수를 초과하여 조회한 스케줄 아이디 리스트 */
  todayOverViewedScheduleIdList: number[];
};

const initialData: GuestFreightViewerData = {
  appTodayMidnight: getAppTodayMidnight(),
  todayGuestViewCount: 0,
  allTimeViewedScheduleIdList: [],
  todayOverViewedScheduleIdList: [],
};

/**
 * 비회원이 조회한 스케줄 조회 정보
 */
const GUEST_FREIGHT_VIEWER_ATOM = atomWithStorage<GuestFreightViewerData>(
  GUEST_FREIGHT_VIEWER_KEY,
  initialData,
  undefined,
  { unstable_getOnInit: true }
);

/**
 * 비회원이 로그인 시 이전에 열어보았던 스케줄을 조회할 수 있도록 하기 위해 전역적으로 관리함
 */
const SELECTED_SCHEDULE_ID_FOR_GUEST_ATOM = atomWithReset<number | undefined>(
  undefined
);

/** 선택한 스케줄의 출발지/도착지 정보 */
const BASE_SELECTED_PORT_INFO = atom<{
  selectedPol: UserPort | undefined;
  selectedPod: UserPort | undefined;
}>({
  selectedPol: undefined,
  selectedPod: undefined,
});

export default {
  GUEST_FREIGHT_VIEWER_ATOM,
  SELECTED_SCHEDULE_ID_FOR_GUEST_ATOM,
  BASE_SELECTED_PORT_INFO,
};
