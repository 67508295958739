import Image from "next/image";
import Router from "next/router";
import { useTranslation } from "next-i18next";
import { useRecoilValue } from "recoil";

import { ShipdaCurrentLanguage } from "@sellernote/shared/src/i18n/i18nForShipda";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/shared/src/states/forwarding/auth";
import { formatDate } from "@sellernote/shared/src/utils/common/date";
import { addEventToGTMDataLayer } from "@sellernote/shared/src/utils/common/gtm";
import { toThousandUnitFormat } from "@sellernote/shared/src/utils/common/number";
import { replaceEmptyToDash } from "@sellernote/shared/src/utils/common/string";
import { getBaseSupply } from "@sellernote/shared/src/utils/forwarding/common";
import { checkIsConsolidationOnlyRoute } from "@sellernote/shared/src/utils/forwarding/consolidation";
import Badge from "@sellernote/shared/src/sds-v2/components/Badge";
import { COLOR, TEXT_COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";

import { getURLWithQuerystringForScheduleSelect } from "../../../../utils/quote";
import { formatPort } from "./utils";

import Styled from "../index.styles";
import { HeroSectionScheduleListWithCountryAndLinerInfo } from "./useGetHeroSectionRateList";

export default function RateItem({
  data,
  onLoginModalOpen,
}: {
  data: HeroSectionScheduleListWithCountryAndLinerInfo | undefined;
  onLoginModalOpen: () => void;
}) {
  const { t } = useTranslation("page-main");

  const isLoggedIn = useRecoilValue(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  if (!data) {
    return null;
  }

  const {
    scheduleId,
    freightType,
    polPortKrName,
    polPortEnName,
    polCountry,
    pol,
    polFlagUrl,
    podPortKrName,
    podPortEnName,
    podCountry,
    pod,
    podFlagUrl,
    logoUrl,
    linerName,
    etd,
    eta,
    originalPrice,
    cost,
    firstDiscountPrice,
    warehouseId,
    warehouseName,
  } = data;

  const canFirstDiscount = Boolean(firstDiscountPrice);

  const isConsolidationOnlyRoute = checkIsConsolidationOnlyRoute({
    polCode: pol,
    podCode: pod,
    freightType,
  });

  const handleItemClick = () => {
    addEventToGTMDataLayer({
      event: "hero_fare_clicked",
    });

    if (!isLoggedIn) {
      onLoginModalOpen();
      return;
    }

    Router.push(
      getURLWithQuerystringForScheduleSelect({ scheduleId, warehouseId })
    );
  };

  const baseSupply = getBaseSupply(freightType);

  return (
    <div>
      <Styled.rateItem onClick={handleItemClick}>
        <div className="logo-badge-container">
          <div className="carrier-logo">
            {logoUrl ? (
              <Image
                src={logoUrl}
                alt={`${linerName} logo`}
                objectFit="contain"
                width={59}
                height={24}
              />
            ) : (
              replaceEmptyToDash(linerName)
            )}
          </div>

          {isConsolidationOnlyRoute && (
            <Badge
              borderType="stroked"
              colorInfo={{
                background: COLOR.secondary_50,
                border: COLOR.secondary_400,
                text: COLOR.secondary_400,
              }}
              label={
                ShipdaCurrentLanguage.currentLanguage == "ko"
                  ? `${warehouseName}${t("page-main:창고_LCL_특송")}`
                  : t("page-main:창고_LCL_특송")
              }
              className="custom-consolidation-badge"
            />
          )}
        </div>

        <div className="start-end-container">
          <div className="route-line" />

          <div className="start-end-route">
            <div className="start-point">
              <div className="country-port">
                {polFlagUrl && (
                  <Image
                    src={polFlagUrl}
                    alt={`${polCountry} flag`}
                    objectFit="contain"
                    width={24}
                    height={16}
                  />
                )}

                <span className="port">
                  {formatPort({
                    portKrName: polPortKrName,
                    portEnName: polPortEnName,
                    code: pol,
                  })}
                </span>
              </div>

              <span className="date departure-date">
                {formatDate({ date: etd, type: "YY_MM_DD" })} (ETD)
              </span>
            </div>

            <div className="end-point">
              <div className="country-port">
                {podFlagUrl && (
                  <Image
                    src={podFlagUrl}
                    alt={`${podCountry} flag`}
                    objectFit="contain"
                    width={24}
                    height={16}
                  />
                )}

                <span className="port">
                  {formatPort({
                    portKrName: podPortKrName,
                    portEnName: podPortEnName,
                    code: pod,
                  })}
                </span>
              </div>
              <span className="date arrival-date">
                {formatDate({ date: eta, type: "YY_MM_DD" })} (ETA)
              </span>
            </div>
          </div>
        </div>

        <div className="price-container">
          {canFirstDiscount && (
            <div className="price">
              <Badge
                borderType="filled"
                colorInfo={{
                  background: COLOR.point_400,
                  text: TEXT_COLOR.white_1,
                }}
                label={t("page-main:첫_운송_할인")}
                className="custom-discount-badge"
              />

              <del>₩{toThousandUnitFormat(originalPrice)}</del>
            </div>
          )}

          <div className="base-supply-price">
            <span className="base-supply">{baseSupply}</span>

            <span className="discounted-price">
              ₩{toThousandUnitFormat(cost)}~
            </span>
          </div>
        </div>
      </Styled.rateItem>
    </div>
  );
}
