import { FORWARDING_TABLE_PER_PAGE_KEY } from "@sellernote/shared/src/constants/forwarding/common";

import { atomWithStorage } from "jotai/utils";

const FORWARDING_TABLE_PER_PAGE = atomWithStorage(
  FORWARDING_TABLE_PER_PAGE_KEY,
  10,
  undefined,
  { unstable_getOnInit: true }
);

export default {
  FORWARDING_TABLE_PER_PAGE,
};
