import styled, { css } from "styled-components";

import { mobile } from "@sellernote/shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";
import { setElevationStyle } from "@sellernote/shared/src/sds-v2/styles/elevation";
import { setFontStyle } from "@sellernote/shared/src/sds-v2/styles/typography";

const container = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 1080px;
  min-height: 704px;
  display: flex;
  flex-direction: column;

  ${mobile(css`
    max-height: initial;
    min-height: initial;
    height: initial;
  `)};
`;

const sectionIntroWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  max-height: 736px;
  min-height: 360px;
  flex: 1 2 auto;
`;

const sectionIntro = styled.section`
  position: absolute;
  min-width: 570px;
  width: 100%;
  max-width: 1162px;
  top: calc(61% - 91px);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;

const mainDescription = styled.div`
  color: ${COLOR.wh};
  ${setElevationStyle("level2", "text-shadow")}
  text-align: center;

  > h2 {
    ${setFontStyle("Head2")};
  }

  > p.desc {
    margin-top: 16px;
    ${setFontStyle("SubHead2")};
    color: ${COLOR.wh_80};
  }
`;

const ctaContainer = styled.div`
  display: flex;
  margin-top: 40px;
  gap: 32px;

  ${mobile(css`
    margin-top: 24px;
    gap: 16px;
  `)};
`;

const videoDimmedLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
`;

const videoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const discountRateContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  gap: 8px;
  background-color: ${COLOR.wh};

  ${mobile(css`
    align-items: flex-start;
    padding: 40px 0 48px 0;
  `)};

  p.desc {
    width: 1188px;
    ${setFontStyle("Body5")};
    color: ${TEXT_COLOR.black_4};
    text-align: right;

    ${mobile(css`
      width: 100%;
      padding: 0 16px;
      text-align: left;
    `)};
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transition-delay: 10ms;
  }
`;

const rateHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 1184px;

  ${mobile(css`
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  `)};

  > h2 {
    ${setFontStyle("Head5")};
    color: ${TEXT_COLOR.black_2};

    ${mobile(css`
      width: 100%;
      ${setFontStyle("Head6")};
      padding: 0 16px;
    `)};
  }
`;

const rateList = styled.ul`
  .slick-slider {
    padding: 0 40px;

    ${mobile(css`
      padding: 0;
    `)};

    .slick-list {
      width: 1188px;

      ${mobile(css`
        width: 588px;
        flex-direction: column;
      `)};

      .slick-track {
        display: flex;
      }

      .slick-slide {
        margin-right: 8px;

        &:first-child {
          ${mobile(css`
            margin-left: 16px;
          `)};
        }
      }
    }

    .slick-current {
      cursor: pointer;
    }

    .slick-arrow::before {
      display: none;
    }

    .slick-arrow {
      width: auto;
    }

    .slick-prev {
      left: 0px;
    }

    .slick-next {
      right: 0px;
    }

    .slick-disabled {
      cursor: default;
    }
  }
`;

const rateItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 290px;
  height: 224px;
  border-radius: 2px;
  border: 1px solid ${COLOR.grayScale_300};
  padding: 16px;
  gap: 12px;
  cursor: pointer;

  &:hover {
    background-color: ${COLOR.bk_2};
  }

  .logo-badge-container {
    display: flex;
    align-items: center;
    gap: 12px;

    // 디자인 요구사항 반영
    .custom-consolidation-badge {
      padding: 0 8px;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: -0.25px;
    }
  }

  .start-end-container {
    display: flex;
    gap: 8px;
  }

  .route-line {
    width: 5px;
    height: 57px;
    margin-top: 10px;
    position: relative;
    border-left: 1px solid ${COLOR.grayScale_300};
  }

  .route-line::before,
  .route-line::after {
    position: absolute;
    display: block;
    width: 6px;
    height: 6px;
    left: -3.5px;
    content: "";
    background-color: ${COLOR.grayScale_300};
    border-radius: 10px;
  }

  .route-line::before {
    top: 0;
  }

  .route-line::after {
    bottom: 0;
  }

  .start-end-route {
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: ${TEXT_COLOR.black_2};
    width: 100%;
  }

  .country-port {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .start-point,
  .end-point {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 40px;
    gap: 2px;
  }

  .port {
    ${setFontStyle("Body3")};
  }

  .date {
    color: ${TEXT_COLOR.black_3};
    ${setFontStyle("Body4")};
    padding-left: 32px;
  }

  .price-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    flex: 1;
    gap: 4px;

    .price {
      display: flex;
      align-items: center;
      gap: 8px;

      /* 디자이너의 요청으로 카드섹션용 뱃지 스타일 커스텀 */
      .custom-discount-badge {
        padding: 4px 6px;
        font-size: 12px;
        line-height: 12px;
      }

      del {
        color: ${TEXT_COLOR.black_4};
        ${setFontStyle("Body3")};
      }
    }

    .base-supply-price {
      display: flex;
      align-items: flex-end;
      gap: 8px;

      .base-supply {
        color: ${TEXT_COLOR.black_3};
        ${setFontStyle("Body3")};
      }

      .discounted-price {
        color: ${TEXT_COLOR.black_2};
        ${setFontStyle("SubHead1")};
      }
    }
  }
`;

export default {
  container,
  sectionIntroWrapper,
  sectionIntro,
  ctaContainer,
  videoDimmedLayer,
  videoWrapper,
  video,
  discountRateContent,
  rateHeader,
  rateList,
  rateItem,
  mainDescription,
};
