import { atom } from "jotai";

import { UserPort } from "@sellernote/shared/src/types/common/common";
import { RecommendLine } from "@sellernote/shared/src/types/forwarding/schedule";

import { FORWARDING_SCHEDULE_ATOMS } from ".";

/** 선택한 포트 정보를 업데이트하는 액션 */
const UPDATE_SELECTED_PORT_INFO = atom(
  null,
  (
    get,
    set,
    { type, portInfo }: { type: "pol" | "pod"; portInfo: UserPort | undefined }
  ) => {
    const selectedPortInfo = get(
      FORWARDING_SCHEDULE_ATOMS.BASE_SELECTED_PORT_INFO
    );

    const updatePortKey = type === "pol" ? "selectedPol" : "selectedPod";

    const updatedSelectedPortInfo = {
      ...selectedPortInfo,
      [updatePortKey]: portInfo,
    };

    set(
      FORWARDING_SCHEDULE_ATOMS.BASE_SELECTED_PORT_INFO,
      updatedSelectedPortInfo
    );
  }
);

/** 선택한 포트 정보를 리셋하는 액션 */
const RESET_SELECTED_PORT_INFO = atom(null, (_, set) => {
  set(FORWARDING_SCHEDULE_ATOMS.BASE_SELECTED_PORT_INFO, {
    selectedPol: undefined,
    selectedPod: undefined,
  });
});

/** 추천/연관 스케줄로 포트 정보를 업데이트하는 액션 */
const UPDATE_SELECTED_INFO_WITH_RECOMMEND_LINE = atom(
  null,
  (_, set, recommendLine: RecommendLine) => {
    const { recommendedPol, recommendedPod } = recommendLine;

    set(FORWARDING_SCHEDULE_ATOMS.BASE_SELECTED_PORT_INFO, {
      selectedPol: recommendedPol,
      selectedPod: recommendedPod,
    });
  }
);

export default {
  UPDATE_SELECTED_PORT_INFO,
  UPDATE_SELECTED_INFO_WITH_RECOMMEND_LINE,
  RESET_SELECTED_PORT_INFO,
};
