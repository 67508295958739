import { CustomArrowProps } from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { COLOR } from "@sellernote/shared/src/stylesToMoveToV1/constants";
import ChevronLeftIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/FourChevronLeftIcon";
import ChevronRightIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/FourChevronRightIcon";

export const PrevArrow = (props?: CustomArrowProps) => {
  if (!props) return null;

  const { className, onClick, currentSlide } = props;

  const isFirstSlide = currentSlide === 0;

  return (
    <div className={className} style={{ display: "block" }} onClick={onClick}>
      <ChevronLeftIcon
        color={isFirstSlide ? COLOR.grayScale_300 : COLOR.grayScale_600}
        width={24}
        height={24}
      />
    </div>
  );
};

export const NextArrow = (props?: CustomArrowProps) => {
  if (!props) return null;

  const { className, onClick, currentSlide } = props;

  const isLastSlide = currentSlide === 4;

  return (
    <div className={className} style={{ display: "block" }} onClick={onClick}>
      <ChevronRightIcon
        color={isLastSlide ? COLOR.grayScale_300 : COLOR.grayScale_600}
        width={24}
        height={24}
      />
    </div>
  );
};
