import { ReactNode } from "react";
import Image from "next/image";
import { Trans, useTranslation } from "next-i18next";

import useOnceActivatedIntersectionObserver from "@sellernote/shared/src/hooks/common/useOnceActivatedIntersectionObserver";
import { useCheckIsMobile } from "@sellernote/shared/src/utils/common/hook";

import CustomerCard from "./CustomerCard";
import Styled from "./index.styles";

type CustomerCardData = {
  logoImage: {
    url: string;
    width: number;
    height: number;
    mobileWidth: number;
    mobileHeight: number;
  };
  blogUrl: string;
  category: string;
  name: string;
  description: ReactNode;
};

export type { CustomerCardData };

const CUSTOMER_CARD_DATA: CustomerCardData[] = [
  {
    logoImage: {
      url: "/assets/images/main/boosters-logo.webp",
      width: 154,
      height: 14,
      mobileWidth: 128,
      mobileHeight: 12,
    },
    blogUrl:
      "https://www.ship-da.com/blog/%EB%B8%8C%EB%9E%9C%EB%93%9C-%EA%B7%B8%EB%A3%B9-%EB%B6%80%EC%8A%A4%ED%84%B0%EC%8A%A4-scm%ED%8C%80-%EB%94%94%EC%A7%80%ED%84%B8-%EB%AC%BC%EB%A5%98-%ED%94%8C%EB%9E%AB%ED%8F%BC-%EC%89%BD%EB%8B%A4-po%EA%B8%B0%EB%8A%A5%EC%9C%BC%EB%A1%9C-%EC%88%98%EC%9E%85-%ED%9A%A8%EC%9C%A8%EC%9D%84-%EA%B7%B9%EB%8C%80%ED%99%94%ED%96%88%EC%96%B4%EC%9A%94-20926",
    category: "브랜드_그룹",
    name: "부스터스",
    description: (
      <Trans
        i18nKey="page-main:_급성장하는_브랜드의_SCM에_br_/_딱_맞는_시스템이에요_"
        components={{
          br: <br />,
        }}
      />
    ),
  },
  {
    logoImage: {
      url: "/assets/images/main/kpmi-logo.webp",
      width: 114,
      height: 24,
      mobileWidth: 94,
      mobileHeight: 20,
    },
    blogUrl:
      "https://www.ship-da.com/blog/%EB%8C%80%ED%95%9C%EB%AF%BC%EA%B5%AD-1%EB%93%B1-%EB%B0%A9%ED%83%84%EC%86%8C%EC%9E%AC-%ED%9A%8C%EC%82%AC-24%EC%8B%9C%EA%B0%84-%EB%AC%BC%EB%A5%98%EC%97%85%EB%AC%B4%EA%B0%80-%EA%B0%80%EB%8A%A5%ED%95%B4%EC%A7%84-%EB%B9%84%EB%B0%80%EC%9D%80-%ED%95%9C%EA%B5%AD%EC%A0%95%EB%B0%80%EC%86%8C%EC%9E%AC%EC%82%B0%EC%97%85-%EC%84%9C%ED%98%84%EA%B5%90-%EB%A6%AC%EB%8D%94-16883",
    category: "방탄소재",
    name: "한국정밀소재산업",
    description: (
      <Trans
        i18nKey="page-main:_물류_전담_담당자_없이도_br_/_공급망_관리가_가능해졌어요_"
        components={{
          br: <br />,
        }}
      />
    ),
  },
  {
    logoImage: {
      url: "/assets/images/main/beatitudo-logo.webp",
      width: 143,
      height: 24,
      mobileWidth: 121,
      mobileHeight: 20,
    },
    blogUrl:
      "https://www.ship-da.com/blog/%EC%8B%A0%EB%A2%B0%EA%B0%80-%EB%AC%B4%EA%B8%B0%EC%9D%B8-%ED%9A%8C%EC%82%AC-%EC%B2%9C%EC%9E%AC%EC%A7%80%EB%B3%80%EC%97%90%EB%8F%84-%EB%B0%B0%EC%86%A1%EC%A7%80%EC%97%B0%EC%9D%B4-%EC%97%86%EB%8D%98-%EC%9D%B4%EC%9C%A0%EB%8A%94-%EB%B2%A0%EC%95%84%ED%8B%B0%ED%88%AC%EB%8F%84-%EA%B9%80%EB%8F%99%EB%AF%BC-%EB%8C%80%ED%91%9C--17469",
    category: "생활용품",
    name: "베아티투도",
    description: (
      <Trans
        i18nKey="page-main:_운임과_세부견적을_바로_알려주니__br_/_물류가_예측_가능해졌어요_"
        components={{
          br: <br />,
        }}
      />
    ),
  },
];

export default function CustomerListSection() {
  const { t } = useTranslation(["page-main"]);

  const { isMobile } = useCheckIsMobile();

  const { target, isActivatedTarget } = useOnceActivatedIntersectionObserver({
    threshold: 0.3,
  });

  return (
    <Styled.container ref={target}>
      <Styled.content>
        <Styled.title
          className={`fade-up-animation ${isActivatedTarget ? "active" : ""}`}
        >
          <strong>1.9K+ </strong>
          <span>
            <Trans
              i18nKey="page-main:누적_고객사의_물류를__mobileBr_/_책임지고_있어요"
              components={{
                mobileBr: <br className="mobile-only" />,
              }}
            />
          </span>
        </Styled.title>

        <Styled.imageList>
          <Image
            className={`customer-list-image-1 fade-up-animation ${
              isActivatedTarget ? "active" : ""
            }`}
            src={
              isMobile
                ? "/assets/images/main/customer-list-section-1-m.webp"
                : "/assets/images/main/customer-list-section-1.webp"
            }
            alt={`customer-list-image`}
            width={isMobile ? 328 : 1160}
            height={isMobile ? 33 : 60}
            priority
          />

          <Image
            className={`customer-list-image-2 fade-up-animation ${
              isActivatedTarget ? "active" : ""
            }`}
            src={
              isMobile
                ? "/assets/images/main/customer-list-section-2-m.webp"
                : "/assets/images/main/customer-list-section-2.webp"
            }
            alt={`customer-list-image`}
            width={isMobile ? 328 : 1160}
            height={isMobile ? 33 : 60}
            priority
          />

          {isMobile && (
            <>
              <Image
                className={`customer-list-image-3 fade-up-animation ${
                  isActivatedTarget ? "active" : ""
                }`}
                src="/assets/images/main/customer-list-section-3-m.webp"
                alt={`customer-list-image`}
                width={328}
                height={33}
                priority
              />

              <Image
                className={`customer-list-image-4 fade-up-animation ${
                  isActivatedTarget ? "active" : ""
                }`}
                src="/assets/images/main/customer-list-section-4-m.webp"
                alt={`customer-list-image`}
                width={328}
                height={33}
                priority
              />
            </>
          )}
        </Styled.imageList>

        <Styled.customerCardContainer>
          {CUSTOMER_CARD_DATA.map((data, index) => (
            <CustomerCard
              key={index}
              {...data}
              delay={(isMobile ? 1.1 : 0.9) + 0.1 * index}
              isActivatedTarget={isActivatedTarget}
            />
          ))}
        </Styled.customerCardContainer>
      </Styled.content>
    </Styled.container>
  );
}
