import styled, { css } from "styled-components";

import { mobile } from "@sellernote/shared/src/styles/functions";
import { TEXT_COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";
import { setFontStyle } from "@sellernote/shared/src/sds-v2/styles/typography";

import {
  fadeInAndSpringAnimation,
  fadeUpAnimation,
} from "../../../components/landing/index.styles";

const container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/assets/images/main/aeo-section-bg.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;

  ${fadeInAndSpringAnimation}

  ${fadeUpAnimation}

  .mobile-only {
    display: none;
  }

  ${mobile(
    css`
      padding: 48px 24px;
      background-image: url("/assets/images/main/aeo-section-bg-m.webp");
      .mobile-only {
        display: initial;
      }
    `
  )}
`;

const content = styled.div`
  position: relative;
  padding: 88px 0;
  width: 1160px;

  .link-button {
    animation-delay: 0.8s;
  }

  ${mobile(
    css`
      width: 100%;
      padding: initial;
    `
  )}
`;

const logoWrapper = styled.div`
  position: absolute;
  top: 82px;
  right: 50px;
  animation-delay: 0.2s;

  ${mobile(
    css`
      position: initial;
      top: initial;
      right: initial;
      margin-bottom: 20px;
    `
  )};
`;

const contentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const titleAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${TEXT_COLOR.white_1};

  h3 {
    ${setFontStyle("Head3")};
    animation-delay: 0.4s;

    ${mobile(
      css`
        ${setFontStyle("Head6")};
      `
    )};
  }

  p {
    ${setFontStyle("Body1")};
    animation-delay: 0.6s;

    ${mobile(
      css`
        ${setFontStyle("Body3")};
      `
    )};
  }
`;

export default {
  container,
  content,
  logoWrapper,
  contentContainer,
  titleAndDescription,
};
