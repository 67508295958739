import { ShipdaCurrentLanguage } from "@sellernote/shared/src/i18n/i18nForShipda";
import { replaceEmptyToDash } from "@sellernote/shared/src/utils/common/string";

const formatPort = ({
  portKrName,
  portEnName,
  code,
}: {
  portKrName: string | undefined;
  portEnName: string | undefined;
  code: string | undefined;
}) =>
  `${replaceEmptyToDash(
    ShipdaCurrentLanguage.currentLanguage === "ko"
      ? portKrName || portEnName
      : portEnName
  )} (${replaceEmptyToDash(code)})`;

export { formatPort };
