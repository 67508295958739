import { useCallback, useEffect } from "react";
import { useAtom } from "jotai";

import { useCheckIsMobile } from "@sellernote/shared/src/utils/common/hook";
import { COMMON_LAYOUT_ATOMS } from "@sellernote/shipda-kr/src/jotaiStates/common/layout";

import { FLOATING_BUTTON_AREA_DEFAULT_POSITION } from "../../constants/layout";

/**
 * 특정 페이지에서 채팅 버튼의 위치를 조정해야 할 경우(ex. 페이지의 특정기능을 가려서 위치를 조정해야하는 경우) 사용.
 *
 * 이 기능을 사용하는 컴포넌트가 unmount될때는 기본 위치로 초기화됨에 유의.
 */
export default function useCustomizeFloatingButtonAreaPosition({
  position,
  disabled,
}: {
  position: number;
  /**
   * 조건부로 기능을 비활성화 하고싶을때 사용(ex. 모바일에서는 사용하지않음)
   */
  disabled?: boolean;
}) {
  const { isMobile } = useCheckIsMobile();

  const [currentPosition, setCurrentPosition] = useAtom(
    COMMON_LAYOUT_ATOMS.FLOATING_BUTTON_AREA_POSITION
  );

  const resetCurrentPosition = useCallback(() => {
    setCurrentPosition(
      FLOATING_BUTTON_AREA_DEFAULT_POSITION[isMobile ? "mobile" : "desktop"]
    );
  }, [isMobile, setCurrentPosition]);

  useEffect(() => {
    if (disabled) return;

    if (currentPosition !== position) setCurrentPosition(position);
  });

  // 사용하는 페이지에서 벗어났을때는 기본 포지션으로 초기화
  useEffect(() => {
    return () => resetCurrentPosition();
  }, [resetCurrentPosition]);
}
