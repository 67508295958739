import React from "react";

import { useCheckIsMobile } from "@sellernote/shared/src/utils/common/hook";

import DiscountRateContent from "./DiscountRateContent";
import TitleAndCta from "./TitleAndCta";
import TitleAndCtaForMobile from "./TitleAndCtaForMobile";
import Styled from "./index.styles";

export default function MainSectionIntro() {
  const { isMobile } = useCheckIsMobile();

  return (
    <Styled.container>
      {isMobile ? <TitleAndCtaForMobile /> : <TitleAndCta />}

      <DiscountRateContent />
    </Styled.container>
  );
}
